import React from "react"
import { Helmet } from "react-helmet"

type Props = {
  title: string
  desc: string
}

export const Seo: React.FC<Props> = ({ title, desc }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={desc} />
      <html lang="fi" />
    </Helmet>
  )
}
